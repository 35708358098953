import React from 'react'

class Perspective extends React.Component {
    constructor(props) {
        super(props);

        this.mod = props.mod || 0.4;

        this.children = props.children;

    }

    moveCamera(event) {
        const data = event
        if (!data.facemesh) {
            return;
        }
        let x = (1 - data.facemesh.multiFaceLandmarks?.[0][0].x) - 0.5;
        let y = data.facemesh.multiFaceLandmarks?.[0][0].y - 0.5;

        x*=this.mod;
        y*=this.mod;

        if (x) {
            document.documentElement.style.setProperty('--x', x);
        }
        if (y) {
            document.documentElement.style.setProperty('--y', y);
        }
    }

    loadHandsfree() {
        if (window.Handsfree) {
            this.handsfree = new window.Handsfree({facemesh: true});
            this.handsfree.enablePlugins('browser');
            this.handsfree.start();
            this.handsfree.on('data', this.moveCamera.bind(this));
        } else {
            requestAnimationFrame(this.loadHandsfree.bind(this))
        }
    }

    componentDidMount() {
        if (typeof window === 'undefined') {
            return;
        }
        if (matchMedia('(hover: hover)').matches) {
            this.loadHandsfree()
        }
    }

    render () {
        return (
            <>
                <div ref={n => this.perspective = n}
                    style={{
                        display: 'contents'
                    }}
                >
                    {this.children}
                </div>
            </>
        )
    }
}

export default Perspective