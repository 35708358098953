import React from "react"
import BackgroundStyles from '../styles/image-background.module.css'

class ImageBackground extends React.Component {
    constructor() {
        super();
        this.state = {
            image: ''
        }
    }

    componentDidMount() {
        this.getImage();
    }

    getImage() {
        this.setState({
            image: 'https://source.unsplash.com/daily?nature'
        });
    }

    render() {
        return (
            <div
                className={BackgroundStyles.background}
                style={{
                    backgroundImage: `url(${this.state.image})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    transform: 'translate(calc(100% * (0.2 * var(--x))),calc(100% * (0.2 * var(--y)))) scale(1.5)',
                    willChange: 'transform',
                }}
            >
            </div>
        )
    }
}

export default ImageBackground