import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import '../styles/variables.css'
import '../styles/layout.css'
import '../styles/type.css'
import GridStyles from '../styles/grid.module.css'
import ImageBackground from './image-background'
import Perspective from './perspective'

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <>
            <div style={{
                perspective: '90em',
            }}>
                <main className={GridStyles.grid} style={{
                    transform: 'rotateY(calc((180deg * var(--x)))) rotateX(calc((-180deg * var(--y)))) translate(calc(100% * (1.1 * var(--x))),calc(100% * (1.1 * var(--y))))',
                    willChange: 'transform',
                }}>
                    {children}
                </main>
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout