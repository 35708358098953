import React, {Suspense} from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
const Todo = React.lazy(() => import('../components/todo'))
const Links = React.lazy(() => import('../components/links'))
const Time = React.lazy(() => import('../components/time'))
const Weather = React.lazy(() => import('../components/weather'))
const Ship = React.lazy(() => import('../components/ship'))
const Points = React.lazy(() => import('../components/points'))
const HA = React.lazy(() => import('../components/ha'))
const isSSR = typeof window === "undefined"
const IndexPage = () => (
    <Layout>
        <SEO title="Home" />
        {!isSSR && (
            <>
                <Suspense fallback=''>
                    <Todo />
                </Suspense>
                <Suspense fallback=''>
                    <Links />
                </Suspense>
                <Suspense fallback=''>
                    <Time />
                </Suspense>
                <Suspense fallback=''>
                    <Weather />
                </Suspense>
                <Suspense fallback=''>
                    <HA />
                </Suspense>
                <Suspense fallback=''>
                    <Points />
                </Suspense>
            </>
        )}
    </Layout>
)
  
export default IndexPage